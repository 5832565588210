/*
 * @Description: 请求文件
 * @Author: 杨志航
 * @Date: 2023-05-25 09:19:06
 */
import config from '@/configs/config'
import { message } from 'antd'
import history from '@/libs/history'
import { messageKey } from '@/libs/constant'

/**
 * header参数设置
 * @param type POST/GET
 * @returns {{headers: {token: *}, method: *}}
 * @private
 */
function _headerOptions(type) {
  let options = {
    method: type,
    mode: "cors",
    headers: {
    },
  }
  let token = localStorage.getItem(config.storageKey.token)
  if (typeof token != 'undefined' && token != '') {
    options.headers.token = token
  }
  return options
}

/**
 * 请求
 * @param 
 * @returns 
 * @private
 */
function _fetcher(url, options) {
  message.loading({
    key: messageKey,
    content: '加载中…',
    duration: 0,
  })
  return new Promise(async (resolve, reject) => {
    const res = await fetch(url, options)
    const content = await res.json()
    const { code } = content

    if (code === 0) {
      message.destroy()
      resolve(content.data)
    } else if (code === 1001) {
      message.warning({
        key: messageKey,
        content: content.message
      })
      localStorage.setItem(config.storageKey.token, '')
      history.push('/') 
      reject(content)
    } else {
      message.error({
        key: messageKey,
        content: content.message
      })
      reject(content)
    }
  })
}

/**
 * post提交数据
 * @param url api url
 * @param data 需要提交的数据object
 * @returns {Promise<any>}
 * @constructor
 */

export const Post = (url, data = {}) => {
  let options = _headerOptions('POST')
  //将json数据转换为formData
  let formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  options.body = formData
  url = config.basePath + url
  return _fetcher(url, options)
}

/**
 * GET获取数据
 * @param url
 * @returns {Promise<any>}
 * @constructor
 */
export const Get = (url, data = {}) => {
  const options = _headerOptions('GET')
  let query = ''
  const queryArr = []
  url = config.basePath + url
  const entries = Object.entries(data)

  if (entries.length > 0) {
    entries.forEach((arr) => {
      queryArr.push(`${arr[0]}=${arr[1]}`)
    })
    query = queryArr.join('&')
  }

  if (query !== '') {
    url += `?${query}`
  }
  return _fetcher(url, options)
}

/**
 * 内网访问，服务端渲染 GET方式
 * @param url
 * @returns {Promise<any | Response>}
 * @constructor
 */
export const LANGet = async (url, data = {}) => {
  let query = ''
  const queryArr = []
  url = config.basePath + url
  const entries = Object.entries(data)

  if (entries.length > 0) {
    entries.forEach((arr) => {
      queryArr.push(`${arr[0]}=${arr[1]}`)
    })
    query = queryArr.join('&')
  }

  if (query !== '') {
    url += `?${query}`
  }
  const options = {
    method: "GET",
    mode: "cors",
    headers: {},
  }
  return _fetcher(url, options)
}

/**
 * 内网访问服务端渲染 POST方式
 * @param url
 * @param data
 * @returns {Promise<any | Response>}
 * @constructor
 */
export const LANPost = (url, data = {}) => {
  //将json数据转换为formData
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  url = config.basePath + url
  const options = {
    method: "POST",
    mode: "cors",
    headers: {},
    body: formData, //提交数据
  }
  return _fetcher(url, options)
}

/**
 * file上传 POST方式
 * @param data
 * @returns {Promise<any | Response>}
 * @constructor
 */
 export const FormPost = async data => {
  const host = data.file.host
  //将json数据转换为formData
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  })
  const res = await fetch(host, {
    method: "POST",
    mode: "cors",
    headers: {},
    body: formData, //提交数据
  })
  return await res.json()
}

export const getExport = async (url, postData) => {
  let options = null
  if (postData) {
    options = _headerOptions('POST')
    options.headers['content-type'] = 'application/json'
    options.body = JSON.stringify(postData)
  } else {
    options = _headerOptions('GET')
  }

  const response = await fetch(config.basePath + url, options)
  response.blob().then((blob) => {
    const a = window.document.createElement('a')
    const downUrl = window.URL.createObjectURL(blob) // 获取 blob 本地文件连接 (blob 为纯二进制对象，不能够直接保存到磁盘上)
    const filename = response.headers.get('Content-Disposition').split('filename=')[1].split('.')
    a.href = downUrl
    a.download = `${decodeURI(escape(filename[0]))}.${filename[1]}`
    a.click()
    window.URL.revokeObjectURL(downUrl)
  })
  return []
}
