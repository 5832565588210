/*
 * @Description: 商品管理
 * @Author: 杨志航
 * @Date: 2023-05-25 16:24:18
 */
import React, { useEffect, useState } from 'react'
import { Card } from 'antd'

import MainLayout from '@/components/MainLayout'

const Goods = () => {

  useEffect(() => {
    
  }, [])


  return (
    <div className="business-goods-container">
      <MainLayout mcurrent='business-goods' openkeys={['business', 'business-goods']}>
        
          <Card>
            <div>商品管理</div>
          </Card>
    
      </MainLayout>
    </div>
  )
}

export default Goods
