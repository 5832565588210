/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-05-24 16:01:38
 */
import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Space } from 'antd'
import { IconAttr } from '@/configs/icons'
import config from '@/configs/config'
import './index.scss'

const pageIndex = config.pagination.page

const HeaderSearch = ({ search = [], resetParams, getList, hasShowAll = true, datas = null } = {}) => {

  const [form] = Form.useForm()
  const [showAll, setShowAll] = useState(true)

  useEffect(() => {
    if (datas) {
      form.setFieldsValue(datas)
    }
  }, [datas])

  const onReset = () => {
    form.resetFields()
    resetParams()
    getList()
  }

  const onFinish = values => {
    values.page = pageIndex
    const entries = Object.entries(values)
    if (entries.length > 0) {
      entries.forEach((arr) => {
        // 当选择全部时，也删除
        if ([undefined, null, 'select-all'].includes(arr[1])) {
          delete values[arr[0]]
        }
        // 时间范围转换
        // before {
        //   'range:time': [startDay, endDay]
        // }
        // after {
        //   timeStart: startDay,
        //   timeEnd: endDay
        // }
        if (arr[0].includes('range:')) {
          const suffix  = arr[0].split(':')[1]
          if (arr[1]) {
            values[suffix + 'Start'] = arr[1][0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
            values[suffix + 'End'] = arr[1][1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
          } else {
            delete values[suffix + 'Start']
            delete values[suffix + 'End']
          }
          delete values[arr[0]]
        }
        // 精确到秒的时间选择器
        if (arr[0].includes('rangeTime:')) {
          const suffix  = arr[0].split(':')[1]
          if (arr[1]) {
            values[suffix + 'Start'] = arr[1][0].format('YYYY-MM-DD HH:mm:ss')
            values[suffix + 'End'] = arr[1][1].format('YYYY-MM-DD HH:mm:ss')
          } else {
            delete values[suffix + 'Start']
            delete values[suffix + 'End']
          }
          delete values[arr[0]]
        }
      })
    }
    getList(values)
  }

  return (
    <div className="header-search-container">
      <Form
        form={form}
        onFinish={onFinish}
      >
        <div style={{ overflow: 'hidden', height: showAll ? 'auto' : '56px' }}>
          <Row gutter={24} style={{minHeight: '56px'}}>
            {search.map(val => (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} key={val.name}>
                <Form.Item initialValue={val.initialValue} name={val.name} key={val.name} label={val.label}>
                  {val.component}
                </Form.Item>
              </Col>)
            )}
          </Row>
        </div>
        <div className="search-btn-super">
          <Space align="start">
            <Button key="search" type="primary" htmlType="submit">查询</Button>
            <Button key="reset" htmlType="button" onClick={onReset}>重置</Button>
            {
              hasShowAll ? showAll ? <span className="show-all-control" onClick={() => setShowAll(false)}>收起<IconAttr icon="UpOutlined" /></span>
                : <span className="show-all-control" onClick={() => setShowAll(true)}>展开<IconAttr icon="DownOutlined" /></span>
                : null
            }
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default HeaderSearch
