/*
 * @Description: 本地路由，上线后 从服务端获取
 * @Author: 杨志航
 * @Date: 2023-05-25 09:54:10
 */

export default {
  page:99,
  perPage:10,
  total:990,
  memberList:[
    {
      id: 1,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    },{
      id: 2,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:1,
    },{
      id: 3,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    },{
      id: 4,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    },{
      id: 5,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    },{
      id: 6,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    },{
      id: 7,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    },{
      id: 8,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    },{
      id: 9,
      memberId: '001',
      name: '张三丰',
      account:'ZhangSanFeng',
      role:'运营、管理员',
      department:'开发部',
      isForbidden:0,
    }
  ]
}
