/*
 * @Description: 用户管理
 * @Author: 杨志航
 * @Date: 2023-05-25 15:35:53
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import MediaAccount from './MediaAccount'
import LandingPage from './LandingPage'

class Member extends Component {
  render () {
    const { path } = this.props.match

    return (
      <Switch>
        <Route path={path + "/mediaaccount"} component={MediaAccount} />
        <Route path={path + "/landingpage"} component={LandingPage} />
      </Switch>
    )
  }
}

export default withRouter(Member)
