/*
 * @Description: 用户管理
 * @Author: 杨志航
 * @Date: 2023-05-25 15:35:53
 */
import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import TaoBao from './TaoBao'
import JingDong from './JingDong'
import PinDuoDuo from './PinDuoDuo'


class Member extends Component {
  render () {
    const { path } = this.props.match

    return (
      <Switch>
        <Route path={path + "/jingdong"} component={JingDong} />
        <Route path={path + "/pinduoduo"} component={PinDuoDuo} />
        <Route path={path + "/taobao"} component={TaoBao} />
      </Switch>
    )
  }
}

export default withRouter(Member)
