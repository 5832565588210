/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2023-05-24 17:50:10
 */
import React, { useState, useEffect } from 'react'
import MainLayout from '@/components/MainLayout'
import { Alert, Card } from 'antd'
import Icons from '@/configs/icons'
import config from '@/configs/config'

function Main() {
  const [userInfo, setUserInfo] = useState({
    name: '', //用户姓名
    loginDate: '', //登录时
  })

  useEffect(() => {
    //获取用户信息
    let user = localStorage.getItem(config.storageKey.userInfo)
    if (user) {
      user = JSON.parse(user)
      setUserInfo(user)
    }
  }, [])

  return (
    <MainLayout mcurrent={'home'} openkeys={['home']} hideslider={true}>
      <Card>
        <div>{Icons.home} 首页</div>
        <Alert
          message={`欢迎使用后台管理系统(V${config.version})，你本次登录时间为${userInfo.loginDate}，加油又是新的一天。`}
          type="success"
          showIcon
          closable
          style={{ marginTop: 10 }}
        />
      </Card>
    </MainLayout>
  )
}

export default Main
