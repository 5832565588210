/*
 * @Description: 落地页管理
 * @Author: 杨志航
 * @Date: 2023-05-25 16:24:18
 */
import React, { useEffect, useState } from 'react'
import config from '@/configs/config'
import HeaderSearch from '@/components/HeaderSearch'
import MainLayout from '@/components/MainLayout'
import {message,Popover,Image,Carousel,Drawer,Tabs,Segmented,Form,Radio,TreeSelect, Input, DatePicker, Select, Button, Spin, Layout, Card, Table, Space, Popconfirm, Modal } from 'antd'
import { Get, Post, getExport } from '@/libs/fetch'
import {allTrim } from '@/libs/util'

import Upload from '@/components/Upload'
import copy from 'copy-to-clipboard'
import './index.scss'
import {
  EditOutlined,
} from '@ant-design/icons';

import ListJs from '@/configs/landingpage'


const { TabPane } = Tabs


const pageIndex = config.pagination.page
const perPage = config.pagination.perPage
let searchParam = {
  page: pageIndex,
  perPage: perPage,
}

const LandingPage = () => {

  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(pageIndex)
  const [pageSize, setPageSize] = useState(perPage)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([])

  const [tabsType, setTabsType] = useState('JD')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formAdd] = Form.useForm()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [formLandingPage] = Form.useForm()
  const [formReturnRatio] = Form.useForm()


  const [filesBanners, setFilesBanners] = useState([])
  const [fileBackground, setFileBackground] = useState('')
  const [fileButton, setFileButton] = useState('')
  const [landingPageTitle, setLandingPageTitle] = useState('')

  const [messageApi, contextHolder] = message.useMessage();





  useEffect(() => {
    resetParams()
    getList()
    
  }, [])

  const getList = values => {
    if (values) {
      const { page, perPage } = searchParam
      searchParam = { page, perPage, ...values }
    }

    setPage(searchParam.page)
    setPageSize(searchParam.perPage)
    setTotal(ListJs.total)
    setDataSource(ListJs.list)

    // setLoading(true)
    // Get('user/get-list', searchParam).then(res => {
    //   if (res.status == "ok") {
    //     setPage(searchParam.page)
    //     setPageSize(searchParam.perPage)
    //     setTotal(res.data.total)
    //     setDataSource(res.data.list)
    //   } else {
    //     message.error(res.message)
    //   }
    //   setLoading(false)
    // }).catch(err => {
    //   setLoading(false)
    // })
  }

  

  const handleAction = (id, action) => {
    setLoading(true)
    Post(`user/${action}`, { id }).then(res => {
      if (res.status == "ok") {
        getList()
      } else {
        message.error(res.message)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  const handleAddLandingPageAction = ()=>{
      setIsModalOpen(true)
  }

  const handleReturnRatioChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue);
    }
  };

  const handleReturnRatioBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
  };

  // 复制文本
  const copyText = (text, msg) => {
    copy(text)
    messageApi.open({
      type: 'success',
      content: msg || '已复制',
    });
  }

  const onShowModal = (menberJson) => {
    setIsModalVisible(true)
    if (JSON.parse(menberJson).length > 0) {
      setModaData(JSON.parse(menberJson))
    } else {
      setModaData([])
    }
  }

  const onFinishAddInfo = () => {
    formAdd.setFieldsValue(allTrim(formAdd.getFieldsValue()))
    formAdd.validateFields().then((values) => {
        console.log(111,values);
        setIsModalOpen(false)
        setIsDrawerOpen(true)
      })
   
		// formAdd.validateFields().then((values) => {
		// 	console.log('values=>', values)
		// 	if (values.file && values.file.length > 0) {
		// 		values.file = values.file[0].url
		// 	}
		// 	if (id) {
		// 		values.id = id
		// 	}
		// 	// 去掉参数中的无效值
    //   Object.keys(values).forEach(key => {
    //     if ([undefined, null, ''].includes(values[key])) {
    //       delete values[key]
    //     }
    //   })
		// 	setLoading(true)
		// 	Post(`app-user-mobile-group/${values.id ? 'edit' : 'add'}`, values).then((res) => {
		// 		setLoading(false)
		// 		if (res.status == 'ok') {
		// 			message.success("保存成功")
		// 			formAdd.setFieldsValue({
		// 				name: undefined,
    //         account: undefined,
    //         password: undefined,
    //         department: undefined,
    //         role: undefined
		// 			})
		// 			setIsModalOpen(false)
		// 			getList()
		// 		} else {
		// 			message.error(res.message)
		// 		}
		// 	})
		// })
	}

  //关闭新增/编辑弹窗
	const onCancelAddInfo = () => {
		formAdd.setFieldsValue({
			name: undefined,
      account: undefined,
      password: undefined,
      department: undefined,
      role: undefined
    
    })
		setIsModalOpen(false)
	}

  const onFinishLandingPage = () => {
    setIsDrawerOpen(false)
  }

  const onCancelLandingPage = () => {
    setIsDrawerOpen(false)
  }

  const onChangeUploadBanner=(data)=>{
    setFilesBanners(data)
  }

  const onFinishReturnRatio = (item) => {
    return new Promise((resolve, reject) => {
      formReturnRatio.setFieldsValue(allTrim(formReturnRatio.getFieldsValue()))
      formReturnRatio.validateFields().then((values) => {
        resolve(null)
        console.log(111,item);
        console.log(111,values.ratio);
      }).catch((errorInfo) => {
        reject(null)
      })
    });
    
  }

  const onChangeUploadBackground=(data)=>{
    if(data){
      setFileBackground(data)
    }else{
      setFileBackground('')
    }
  }

  const onChangeUploadButton=(data)=>{
    if(data){
      setFileButton(data)
    }else{
      setFileButton('')
    }
  }

  const onChangeTitle=(input)=>{
    const title = document.getElementById("input-title").value;
    setLandingPageTitle(title);
  }

  const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 12 },
		}
	}

  const formItemLayoutLandingPage = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}

  const headerConfig = {
    search: [{
      label: '媒体',
      name: 'userName',
      component: <Select placeholder="请选择" allowClear>
        <Select.Option value="select-all">全部</Select.Option>
      </Select>
    }, {
      label: '账户ID',
      name: 'phone',
      component: <Input placeholder="请输入" />
    }, {
      label: '账户名称',
      name: 'phone',
      component: <Input placeholder="请输入" />
    }, {
      label: '销售部门',
      name: 'userName',
      component: <Select placeholder="请选择" allowClear>
        <Select.Option value="select-all">全部</Select.Option>
      </Select>
    }, {
      label: '关联销售',
      name: 'phone',
      component: <Input placeholder="请输入" />
    }, {
      label: '运营部门',
      name: 'userName',
      component: <Select placeholder="请选择" allowClear>
        <Select.Option value="select-all">全部</Select.Option>
      </Select>
    }, {
      label: '关联运营',
      name: 'phone',
      component: <Input placeholder="请输入" />
    }, {
      label: '关联店铺',
      name: 'userName',
      component: <Select placeholder="请选择" allowClear>
        <Select.Option value="select-all">全部</Select.Option>
      </Select>
    }, {
      label: '关联商品',
      name: 'userName',
      component: <Select placeholder="请选择" allowClear>
        <Select.Option value="select-all">全部</Select.Option>
      </Select>
    }]
  }



  const cols = [{
    title: '媒体',
    dataIndex: 'id'
  }, {
    title: '关联商品',
    dataIndex: 'userName'
  }, {
    title: '关联商品ID',
    dataIndex: 'userName',
  }, {
    title: '关联店铺',
    dataIndex: 'userName',
  }, {
    title: '关联店铺ID',
    dataIndex: 'userName',
  }, {
    title: '关联运营',
    dataIndex: 'userName'
  }, {
    title: '关联销售',
    dataIndex: 'userName'
  }, {
    title: '电商平台',
    dataIndex: 'userName'
  }, {
    title: '电商平台Android appid',
    dataIndex: 'userName'
  }, {
    title: '电商平台IOS appid',
    dataIndex: 'userName'
  }, {
    title: '一跳落地页链接',
    dataIndex: 'urlOneLevel',
    align: 'center',
    render: (text, record) => text ? <>
                                      <Popover content={text} >
                                        {contextHolder}
                                        <a onClick={() => copyText(text)}>复制</a>
                                      </Popover>
                                    </>:<span style={{color:'#999'}}>复制</span>
  }, {
    title: '二跳落地页链接',
    dataIndex: 'urlOneLevel',
    align: 'center',
    render: (text, record) => text ? <>
                                      <Popover content={text} >
                                        {contextHolder}
                                        <a onClick={() => copyText(text)}>复制</a>
                                      </Popover>
                                    </>:<span style={{color:'#999'}}>复制</span>
  }, {
    title: '直达链接',
    dataIndex: 'urlOneLevel',
    align: 'center',
    render: (text, record) => text ? <>
                                      <Popover content={text} >
                                        {contextHolder}
                                        <a onClick={() => copyText(text)}>复制</a>
                                      </Popover>
                                    </>:<span style={{color:'#999'}}>复制</span>
  }, {
    title: '微信小程序长链接',
    dataIndex: 'urlOneLevel',
    align: 'center',
    render: (text, record) => text ? <>
                                      <Popover content={text} >
                                        {contextHolder}
                                        <a onClick={() => copyText(text)}>复制</a>
                                      </Popover>
                                    </>:<span style={{color:'#999'}}>复制</span>
  }, {
    title: '微信小程序原始ID',
    dataIndex: 'userName'
  }, {
    title: '微信小程序APPID',
    dataIndex: 'userName'
  }, {
    title: '点击监测链接',
    dataIndex: 'urlOneLevel',
    align: 'center',
    render: (text, record) => text ? <>
                                      <Popover content={text} >
                                        {contextHolder}
                                        <a onClick={() => copyText(text)}>复制</a>
                                      </Popover>
                                    </>:<span style={{color:'#999'}}>复制</span>
  }, {
    title: '曝光监测链接',
    dataIndex: 'shippingAddress',
    dataIndex: 'urlOneLevel',
    align: 'center',
    render: (text, record) => text ? <>
                                      <Popover content={text} >
                                        {contextHolder}
                                        <a onClick={() => copyText(text)}>复制</a>
                                      </Popover>
                                    </>:<span style={{color:'#999'}}>复制</span>
  }, {
    title: '有效触点监测链接',
    dataIndex: 'urlOneLevel',
    align: 'center',
    render: (text, record) => text ? <>
                                      <Popover content={text} >
                                        {contextHolder}
                                        <a onClick={() => copyText(text)}>复制</a>
                                      </Popover>
                                    </>:<span style={{color:'#999'}}>复制</span>
  }, {
    title: '回传比例',
    dataIndex: 'returnRatio',
    render: (text, record) => <div style={{
      whiteSpace:"nowrap",
      display: 'flex',
      alignItems: 'center',
      }}> 
        <div>{text+"%"}</div> 
        <Popconfirm
          placement="topRight"
          icon={null}
          title={
            
            <Form form={formReturnRatio}>
              <Form.Item
                label=""
                name="ratio"
                rules={[{ required: true, pattern:/^([1-9]?[0-9]{0,1}|100)$/,message: '请输入0-100整数' }]}
                >
                    <Input id={"returnRatio-"+record.id} style={{width:'220px', fontSize: '9px', flex:1, marginRight:'5px'}} placeholder="请输入0-100整数" defaultValue={text} suffix="%" 
                      type="number" min='0' max='100' />
                </Form.Item>
            </Form>
          }
          onConfirm={()=> onFinishReturnRatio(record)}
          okText="确定"
          cancelText="取消"
          destroyTooltipOnHide={true}
          >
          <EditOutlined style={{marginLeft: '5px'}} />
        </Popconfirm>
      </div>
  }, {
    title: '操作',
    key: 'action',
    width: 100,
    align: 'center',
    fixed: 'right',
    render: (text, record) => (
      <Space align="start">
        {
          <a onClick={() => handleAction(record.id, 'unfreeze')}>编辑</a>
        }
      </Space>
    )
  }]

  
  const onChangeTabs = key => {
    if (key === 'JD') {
      setTabsType("JD");
      resetParams()
      getList()
    }else{
      setTabsType("PDD");
    }
  }

    

  const onChangeTable = (pagination, filters, sorter) => {
    searchParam.page = pagination.current
    searchParam.perPage = pagination.pageSize
    getList()
  }

 

  const resetParams = () => {
    searchParam = {
      page: pageIndex,
      perPage: perPage,
    }
    setPageSize(perPage)
  }


  const modaColumn = [{
    title: '序号',
    dataIndex: 'id',
    render: (text, record, index) => index + 1
  }, {
    title: '姓名',
    dataIndex: 'name',
  }, {
    title: '手机号',
    dataIndex: 'mobile',
  }, {
    title: '所在学校',
    dataIndex: 'schoolName',
  }, {
    title: '学号',
    dataIndex: 'studentNo',
  }, {
    title: '在读学历',
    dataIndex: 'education',
  }, {
    title: '所读专业',
    dataIndex: 'schoolMajor',
  }, {
    title: '院系',
    dataIndex: 'schoolDepartment',
  }, {
    title: '毕业年份',
    dataIndex: 'leaveSchoolYear',
  }]

  return (
    <div className="promotion-landingpage-container">
      <MainLayout mcurrent='promotion-landingpage' openkeys={['promotion', 'promotion-landingpage']}>
        <Spin spinning={loading} tip="Loading...">
          <Card>
            <div style={{ backgroundColor: '#fff', padding: '15px 10px', height: 'auto' }}>
              <HeaderSearch
                {...headerConfig}
                getList={getList}
                resetParams={resetParams}
              ></HeaderSearch>
            </div>
            <div className="line"></div>
            <Tabs onChange={onChangeTabs} type="card">
              <TabPane tab="京东" key="JD">
              </TabPane>
              <TabPane tab="拼多多" key="PDD">
              </TabPane>
            </Tabs>
            <div className="table-btn-super" style={{ justifyContent: 'flex-end' }}>
                <Button type='primary' onClick={()=> handleAddLandingPageAction()}>新增落地页</Button>
              </div>
              <Table
                columns={cols}
                rowKey="id"
                dataSource={dataSource}
                scroll={{ x: 'max-content' }}
                bordered
                pagination={{
                  defaultCurrent: pageIndex,
                  pageSize: pageSize,
                  total: total,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  current: page,
                }}
                onChange={onChangeTable}
                footer={() => (<>
                  第{page}页，共{Math.ceil(total / searchParam.perPage)}页，共{total}条
                </>)}
              ></Table>
          </Card>
        </Spin>
      </MainLayout>

      <Modal
        title={"新增落地页信息"}
        open={isModalOpen}
        onOk={() => onFinishAddInfo()}
        onCancel={onCancelAddInfo}
        destroyOnClose
        cancelText="取消"
        okText="下一步">
        <Form form={formAdd} {...formItemLayout} >
            <Form.Item
                label="媒体"
                name="name"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" allowClear>
                  <Select.Option value="JD">京东</Select.Option>
                  <Select.Option value="PDD">拼多多</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="账户名称"
                name="account"
                rules={[{ required: true, message: '请输入' }]}
            >
                <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item
                label="账户ID"
                name="account"
                rules={[{ required: true, message: '请输入' }]}
            >
                <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item
                label="电商平台"
                name="name"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" defaultValue={tabsType} allowClear disabled>
                  <Select.Option value="JD">京东</Select.Option>
                  <Select.Option value="PDD">拼多多</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="店铺名称"
                name="account"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" allowClear>
                  <Select.Option value="JD">京东</Select.Option>
                  <Select.Option value="PDD">拼多多</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="店铺编号"
                name="account"
                rules={[{ required: true, message: '请输入' }]}
            >
                <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item
                label="商品名称"
                name="password"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" allowClear>
                  <Select.Option value="JD">京东</Select.Option>
                  <Select.Option value="PDD">拼多多</Select.Option>
                </Select>
            </Form.Item>
            {
              tabsType == "JD"?(
                <>
                <Form.Item
                    label="SKUID"
                    name="account"
                    rules={[{ required: true, message: '请输入' }]}
                >
                    <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                    label="SKU链接"
                    name="account"
                    rules={[{ required: true, message: '请输入' }]}
                >
                    <Input placeholder="请输入" disabled/>
                </Form.Item>

                </>
              ):(
                <>
                  <Form.Item
                    label="商品ID"
                    name="account"
                    rules={[{ required: true, message: '请输入' }]}
                    >
                    <Input placeholder="请输入"/>
                  </Form.Item>
                  <Form.Item
                    label="推广位ID"
                    name="account"
                    rules={[{ required: true, message: '请输入' }]}
                    >
                    <Input placeholder="请输入" disabled/>
                  </Form.Item>
                </>
              )
            }
            <Form.Item
                label="运营部门"
                name="account"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" allowClear disabled>
                </Select>
            </Form.Item>
            <Form.Item
                label="关联运营"
                name="account"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" allowClear disabled>
                </Select>
            </Form.Item>
            <Form.Item
                label="销售部门"
                name="account"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" allowClear disabled>
                </Select>
            </Form.Item>
            <Form.Item
                label="关联销售"
                name="account"
                rules={[{ required: true, message: '请选择' }]}
            >
                <Select placeholder="请选择" allowClear disabled>
                </Select>
            </Form.Item>
        </Form>
    </Modal>

    <Drawer
        title={"自建落地页（二跳）"}
        placement="right"
        size={'large'}
        onClose={onCancelLandingPage}
        open={isDrawerOpen}
        extra={
          <Space>
            <Button onClick={onCancelLandingPage}>取消</Button>
            <Button type="primary" onClick={onFinishLandingPage}>
              确定
            </Button>
          </Space>
        }
      >
        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
          <div style={{width:'50%'}}>
            <Form form={formLandingPage} {...formItemLayoutLandingPage} >
              <Form.Item
                label="标题"
                name="account"
                rules={[{ required: true, message: '请输入' }]}
                >
                    <Input id='input-title' onChange={onChangeTitle} placeholder="请输入" />
                </Form.Item>
                <Form.Item
                  label="轮播图"
                  name="mainPic1"
                  extra="支持上传png/jpg/jpeg格式图片，允许上传多个"
                >
                  <Upload 
                  maxLength={10000}
                  onChange={onChangeUploadBanner}
                  ></Upload>
                </Form.Item>
                <Form.Item
                  label="背景图"
                  name="mainPic2"
                  rules={[{ required: true, message: '请上传' }]}
                  extra="支持上传png/jpg/jpeg格式图片，上传1个"
                >
                  <Upload 
                    maxLength={1}
                    onChange={onChangeUploadBackground}
                  ></Upload>
                </Form.Item>
                <Form.Item
                  label="按钮图片"
                  name="mainPic3"
                  rules={[{ required: true, message: '请上传' }]}
                  extra="支持上传png/jpg/jpeg格式图片，上传1个"
                >
                  <Upload 
                    maxLength={1}
                    onChange={onChangeUploadButton}
                  ></Upload>
                </Form.Item>
                <Form.Item
                    label="按钮"
                    name="role"
                    rules={[{ required: true, message: '请选择' }]}
                >
                    <Radio.Group>
                        <Radio value={1}>点击跳转</Radio>
                        <Radio value={2}>自动跳转</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
          </div>
          <div style={{width:'50%'}}>
            <div style={{width:'240px',height:'410px',float:'right',backgroundColor:'#999', position: 'relative'}}>
              <div style={{width:'240px',height:'20px',backgroundColor:'#333',textAlign:'center',color:'#FFF'}}>
                {landingPageTitle}
              </div>
              <div style={{width:'240px',height:'390px',position: 'relative'}}>
                { (fileBackground && fileBackground.length > 0) && <Image style={{position:'relative',zIndex:1}}
                  width={240}
                  height={390}
                  preview={false}
                  src={fileBackground}/>
                }
                <div style={{position:'absolute', top: 0, left: 0, zIndex:2,width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                  <Carousel autoplay style={{width:'240px',position:'top'}}>
                    {
                      filesBanners.map((url, index) => (
                        <div key={index}>
                          <Image style={{objectFit:'cover'}}
                            width={240}
                            height={130}
                            preview={false}
                            src={url}
                          />
                        </div>
                      ))
                    }
                  </Carousel>

                  { (fileButton && fileButton.length > 0) && <img src={fileButton} style={{width:'100%', height:'40px'}}></img>}
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
        
      </Drawer>
    </div>
  )
}

export default LandingPage

