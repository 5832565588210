/*
 * @Description: form item 上传图片组件
 * @Author: 王建军<wangjianjun@372163.com>
 * @Date: 2021-05-13 13:59:24
 */
import { useEffect, useState, useRef } from 'react'
import AliyunOSSUpload from '@/components/AliyunOSSUpload'

const Upload = ({
  value,
  onChange = () => {},
  onThumbUrlChange = () => {},
  maxLength = false, 
  accept = '.jpg,.jpeg,.png,.JPG,.JPEG,.PNG',
  accepts = '.jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF',
  size = '10',
  disabled= false,
  fixedWidth,
  fixedHeight,
  type
}) => {
  const [fileList, setFileList] = useState([])
  const inited = useRef(false)
  useEffect(() => {
    if (inited.current) return
    if (typeof value === 'string' && value) {
      setFileList([{
        uid: -1,
        name: value,
        status: "done",
        url: value
      }])
      inited.current = true
    }
    if (Array.isArray(value) && value.length) {
      setFileList(value.map((val, index) => ({
        uid: index,
        name: index + ".png",
        status: "done",
        image: val.image,
        url: val.image.includes('http') ? val.image : val.image
      })))
      inited.current = true
      onChange && onChange(value.map(val => val.image))
    }
  }, [value]) 

  const onUploadChange = (files) => {
    let validFileList = []
    inited.current = true
    if (+maxLength === 1) {
      validFileList = files.length > 0 ? [files[files.length - 1]] : []
      setFileList(validFileList)
      onChange && onChange(validFileList[0] ? validFileList[0].url : null)
      onThumbUrlChange(validFileList[0] ? validFileList[0].thumbUrl : null)
    } else {
      validFileList = files.length > 0 ? files : []
      setFileList(validFileList)
      onChange && onChange(validFileList.slice(0, maxLength).map(val => val.image || val.url))
    }
  }

  return <AliyunOSSUpload
    fileList={fileList}
    accept={type === 1 ? accepts : accept}
    size={type === 1 ? '20' : size}
    listType="picture-card"
    onChange={files => onUploadChange(files)}
    showUploadList={{
      showPreviewIcon: false
    }}
    maxCount={maxLength}
    disabled={disabled}
    type={type}
    fixedWidth={fixedWidth}
    fixedHeight={fixedHeight}
  ></AliyunOSSUpload>
}

export default Upload
