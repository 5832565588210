/*
 * @Description: 本地路由，上线后 从服务端获取
 * @Author: 杨志航
 * @Date: 2023-05-25 09:54:10
 */

export default [{
  "id": "1",
  "icon": "user",
  "title": "机构管理",
  "key": "organ",
  "url":"/organ",
}, {
  "id": "2",
  "icon": "ShopOutlined",
  "title": "商家管理",
  "key": "business",
  "url":"/business/shop",
  "sub":[{
    "id":"10",
    "icon":"ShopOutlined",
    "title":"店铺管理",
    "key":"business-shop",
    "url":"/business/shop",
  }, {
    "id":"11",
    "icon":"ShopOutlined",
    "title":"商品管理",
    "key":"business-goods",
    "url":"/business/goods",
  }]
}, {
  "id": "3",
  "icon": "AppStore",
  "title": "推广管理",
  "key": "promotion",
  "url":"/promotion/landingpage",
  "sub":[{
    "id":"13",
    "icon":"AppStore",
    "title":"落地页管理",
    "key":"promotion-landingpage",
    "url":"/promotion/landingpage",
  }]
}, {
  "id": "4",
  "icon": "ProfileOutlined",
  "title": "报表管理",
  "key": "reportforms",
  "url": "/reportforms/jingdong",
  "sub": [ {
    "id": "14",
    "icon": "ProfileOutlined",
    "title": "京东",
    "key": "reportforms-jingdong",
    "url": "/reportforms/jingdong",
  },
  {
    "id":"15",
    "icon":"ProfileOutlined",
    "title":"拼多多",
    "key":"reportforms-pinduoduo",
    "url":"/reportforms/pinduoduo",
  }]
}]
