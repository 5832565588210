/*
 * @Description: 本地路由，上线后 从服务端获取
 * @Author: 杨志航
 * @Date: 2023-05-25 09:54:10
 */

export default {
  userDepartmentId:"0-0-0",
  departmentList:[
    {
      title: '北京全时天地在线网络信息股份有限公司',
      key: '0',
      canAdd:true,
      canUpdate:true,
      children: [
        {
          title: '研发部',
          key: '0-0',
          canAdd:true,
          canUpdate:true,
          children: [
            {
              title: '开发部',
              key: '0-0-0',
              canAdd:true,
              canUpdate:true
            },
            {
              title: '测试部',
              key: '0-0-1',
              canAdd:true,
              canUpdate:true
            },
            {
              title: '产品部',
              key: '0-0-2',
              canAdd:true,
              canUpdate:true
            }
          ]
        },
        {
          title: '商业中心部',
          key: '0-1',
          canAdd:true,
          canUpdate:true,
          children: [
            {
              title: '产品部',
              key: '0-1-0',
              canAdd:true,
              canUpdate:true
            },
            {
              title: 'UI部',
              key: '0-1-1',
              canAdd:true,
              canUpdate:true
            },
            {
              title: '运营部',
              key: '0-1-2',
              canAdd:true,
              canUpdate:true
            }
          ]
        }
      ]
    }
  ]
}
