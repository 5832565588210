import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'
import history from '@/libs/history'
import App from './App';
import './App.scss'

ReactDOM.render(
  <Router history={history}>
		<App/>
	</Router>,
  document.getElementById('root')
);
