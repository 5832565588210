/*
 * @Description:
 * @Author: 王建军<wangjianjun@372163.com>
 * @Date: 2021-04-14 12:51:27
 */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Modal, message, Tabs } from 'antd'
import Icons from '@/configs/icons'
import { withRouter } from 'react-router-dom'
import config from '@/configs/config'
import { Post } from '@/libs/fetch'
import defaultRouter from '@/configs/router'
import './index.scss'

const { Header } = Layout
const { confirm } = Modal

const MainHeader = ({ history, ...props }) => {
  const [menudata, setMenuData] = useState([]) //菜单数据
  const [userInfo, setUserInfo] = useState({
    name: '', //用户姓名
    loginDate: '', //登录时间
  })

  useEffect(() => {
    //获取用户信息
    let user = localStorage.getItem(config.storageKey.userInfo)
    if (user) {
      user = JSON.parse(user)
      setUserInfo(user)
    }

    setMenuData(defaultRouter)
  }, [])

  //点击菜单
  const menuClick = evt => {
    if (evt.key !== 'logout'){
      console.log("lgs",evt);
      const mainKey = evt.key.split('-')[0]
      const active = menudata.find(item => item.key === mainKey)
      history.push(active.url)
    }else{
      confirm({
        title: '提示',
        icon: Icons.warning,
        content: '确定退出吗？',
        cancelText: '取消',
        okText: '确定',
        onOk() {
          Post("system-user/sign-out").then(res=>{
            if(res.status == 'ok'){
              localStorage.removeItem(config.storageKey.token) //清除对应的cookie
              localStorage.removeItem(config.storageKey.loginRemember) //清除对应的loginRemember
              localStorage.removeItem('siderMenu')
              history.push('/')
            }else{
              message.error(res.message)
            }
          })
        }
      })
    }
  }

  return (
    <Header className="main-layout-header-container" theme="dark">
       <div className="left-log">
          <Link to="/main">
            <img alt="logo" src="https://tdonline-metasite.oss-cn-beijing.aliyuncs.com/admin/tdonline-logo.png" width={30} height={30} />
            <span className="title">天枢运营平台</span>
          </Link>
        </div> 
        <Menu className="middle-tab"
          theme='dark'
          mode="horizontal" 
          selectable={true} 
          selectedKeys={[props.mcurrent?(props.mcurrent.split('-')[0]):""]}
          defaultOpenKeys={props.mcurrent?(props.mcurrent.split('-')[0]):""}
          onClick={menuClick}
          items={menudata.map(item =>  {
            const { title, key, url, sub } = item
            return {
              label: title,
              key,
              url:url,
            };
          })}
        />
        <Menu className="right-info"
          theme='dark'
          mode="horizontal" 
          selectable={false} 
          onClick={menuClick}
          items={[{
              label: userInfo.name,
              key: 'urser',
              icon: Icons.user,
              children: [
                {
                  label: '退出登录',
                  key: 'logout'
                }
              ]
            }
          ]}
        />
    </Header>
  )
}

export default withRouter(MainHeader)
