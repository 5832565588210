/*
 * @Description: 店铺管理
 * @Author: 杨志航
 * @Date: 2023-05-25 16:24:18
 */
import React, { useEffect, useState } from 'react'
import { Card } from 'antd'

import MainLayout from '@/components/MainLayout'

const Shop = () => {

  useEffect(() => {
    
  }, [])


  return (
    <div className="business-shop-container">
      <MainLayout mcurrent='business-shop' openkeys={['business', 'business-shop']}>
        
          <Card>
            <div>店铺管理</div>
          </Card>
    
      </MainLayout>
    </div>
  )
}

export default Shop
