/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2023-05-10 14:57:09
 */
import React, { useState, useEffect } from 'react'
import { Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Get } from '@/libs/fetch'
import { generateUUID } from '@/libs/util'

const MEASURE_SIZE = 200

const AliyunOSSUpload = ({
  children,
  onChange,
  onError,
  fileList: propsFileList,
  onPreview = () => {},
  type,
  ...others
}) => {
  const [OSSData, setOSSData] = useState({});
  const [loading, setLoading] = useState(false)
  const [inited, setInited] = useState(false)
  const [fileList, setFileList] = useState([])
  const [files, setFiles] = useState({})

  useEffect(() => {
    if (!!propsFileList && !inited) {
      const fileList = getFileList(propsFileList)
      setFileList(fileList)
    }
  }, [propsFileList]);


  const getFileList = (propsFileList) => {
    let fileList = [];
    if (Array.isArray(propsFileList)) {
      fileList = [...propsFileList];
    }

    if (typeof propsFileList === 'string') {
      let name = propsFileList.split('/').pop();
      fileList = [
        {
          uid: '0',
          name: name,
          status: 'done',
          url: propsFileList,
        },
      ];
    }
    return fileList;
  };

  const getUploadSign = () => {
    return new Promise((resolve, reject) => {
        Get('upload/upload-sign').then(data => {
          setOSSData(data)
          resolve(true)
        })
    })
  }

  const getImageInfo = file => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      const canvas = document.createElement('canvas');
      filereader.onload = (e) => {
        const src = e.target.result;
        const image = new Image();
        image.onload = () => {
          const { width, height } = image;
          let drawWidth = MEASURE_SIZE;
          let drawHeight = (height / width) * MEASURE_SIZE;
          canvas.width = drawWidth;
          canvas.height = drawHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, drawWidth, drawHeight);
          const thumbUrl = canvas.toDataURL();
          resolve({
            width,
            height,
            thumbUrl,
          });
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  };

  const onUploadChange = ({ fileList, file }) => {
    const { maxCount } = others
    if (maxCount > 1 && fileList.length > maxCount) {
      setFileList(fileList.slice(0, maxCount))
      message.error(`最多上传${maxCount}个`)
      return
    }

    if (file.status === 'error') {
      setLoading(false)
      message.error('上传失败，请重新上传！')
      return
    }

    if (maxCount === 1) {
      setFileList(fileList.length > 0 ? [fileList[fileList.length - 1]] : [])
    } else {
      setFileList([...fileList])
    }

    setInited(true)

    if (file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (file.status === 'done') {
      if (file.response.Status === 'Ok') {
        setLoading(false);
      }
    }

    if (onChange) {
      if (maxCount === 1) {
        onChange([fileList[fileList.length - 1]]);
      } else {
        onChange([...fileList]);
      }
    }
  };

  const onRemove = (file) => {
    // const { value } = others;
    // const files = value.filter((v) => v.url !== file.url);
    if (onChange) {
      // onChange(files);
    }
  };

  const getExtraData = file => {
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = generateUUID() + suffix;
    if (type === 1) {
      file.url = OSSData.host+ '/' + OSSData.dir + filename + '?width=' + files.width + '&height=' + files.height;
    } else {
      file.url = OSSData.host+ '/' + OSSData.dir + filename;
    }

    return {
      name: file.name,
      key: OSSData.dir + filename,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy,
      Signature: OSSData.signature,
      callback: OSSData.callback,
      success_action_status: '200',
    };
  };

  const beforeUpload = async (file) => {
    setFiles(file)
    return new Promise(async (resolve, reject) => {
      if (others.accept) {
        let type = file.name.substring(file.name.lastIndexOf('.'));
        if (!others.accept.includes(type)) {
          onError
            ? onError('FILE-TYPE')
            : message.error(`仅支持${others.accept}格式的文件`);
          return reject(false);
        }
      }
      const { width, height, size, scale, fixedWidth, fixedHeight } = others;
      if (!OSSData.expire) {
        await getUploadSign();
      }
      const expire = OSSData.expire * 1000;
      if (expire < Date.now()) {
        await getUploadSign();
      }
      if (size) {
        const isLessSize = file.size / 1024 / 1024 < size;
        if (!isLessSize) {
          message.error(`文件大小必须小于 ${size}MB！`);
          return reject(false);
        }
      }

      if (file.type.indexOf('image/') === 0) {
        let imageInfo = await getImageInfo(file);
        file.width = imageInfo.width;
        file.height = imageInfo.height;
        file.thumbUrl = imageInfo.thumbUrl;
        if (
          scale &&
          Math.abs(imageInfo.width / imageInfo.height - scale) > 0.1
        ) {
          if(scale == "1") {
            message.error(`图片宽高度比必须为1:1等比例正方形`);
          } else {
            message.error(`图片宽高比必须为 ${scale}！`);
          }
          return reject(false);
        }
        if (
          (fixedWidth && +fixedWidth !== imageInfo.width) ||  
          (fixedHeight && +fixedHeight !== imageInfo.height)
          ) {
          message.error(`图片尺寸应为 ${fixedWidth} * ${fixedHeight} px！`);
          return reject(false);
        }
        if (width && +width < imageInfo.width) {
          message.error(`图片宽必须小于 ${width}px！`);
          return reject(false);
        }
        if (height && +height < imageInfo.height) {
          message.error(`图片高必须小于 ${height}px！`);
          return reject(false);
        }
      }
      return resolve(file);
    });
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>点击上传</div>
    </div>
  )

  return (
    <Upload
      {...others}
      fileList={fileList}
      action={OSSData.host}
      onChange={onUploadChange}
      onRemove={onRemove}
      // onPreview={onPreview}
      data={getExtraData}
      beforeUpload={beforeUpload}
    >
      {children ? (children=='disable' ? null:children) : uploadButton}
    </Upload>
  );
};

export default AliyunOSSUpload;
