/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-06-15 09:19:06
 */
export default {
  basePath: '/backend/', //api 前缀
  version: '1.0.0',
  storageKey: {
      loginRemember: 'USER_RM', //是否记住登录
      token: 'USER_TOKEN', //系统的token值
      userInfo: 'USER_INFO', //员工信息
  },
  pagination: {
      page: 1, // 当前页
      perPage: 10, // pageSize
  },
  paginationModal: {
      page: 1, // 当前页
      perPage: 3, // pageSize
  }
}
