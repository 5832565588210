/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-06-13 15:24:00
 */
import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'

const NoMatch = ()=>{
  return (
    <Result
      status="404"
      title="404"
      subTitle="未找到页面"
      extra={<Link to="/">回到首页</Link>}
    />
  )
}

export default NoMatch
