/*
 * @Description: 常量表
 * @Author: 杨志航
 * @Date: 2023-05-04 14:14:28
 */
// 正则
export const regExt = {
  phone: /^1[3456789]\d{9}$/,
  email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
  account: /^([A-Za-z0-9\.\@\!\#\$\%\^\&\*\(\)]){6,50}$/, // 允许输入6-50位英文、数字、符号，英文不区分大小写
  identity: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  bankNo: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,
  nickname: /^[\u4e00-\u9fa5_a-zA-Z0-9_]{2,12}/,
  positiveInt: /^[1-9]\d*$/,
}

export const messageKey = 'message-key'
