/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-06-13 15:32:25
 */
import { Get } from './fetch'
import dayjs from 'dayjs'

const verify = {
  isNumber: function (val) {
    let regPos = /^\d+(\.\d+)?$/; //非负浮点数
    let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if (regPos.test(val) && regNeg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  isIntNumber(val) {
    const reg = /^[+]{0,1}(\d+)$/
    return reg.test(val)
  },
  // 验证手机号
  isMobile(val) {
    const reg = /^1[3456789]\d{9}$/
    return reg.test(val)
  },
  // 验证大写字母
  isUpperCase(val) {
    const reg = /^[A-Z]+$/
    return reg.test(val)
  },
  // 验证appId
  isAppId(val) {
    const reg = /^[A-Za-z0-9._]+$/
    return reg.test(val)
  },
  formatDateTime: (date = +new Date()) => {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
  },
}
export default verify

// 解析search查询字符串
export const parseSearch = (path) => {
  const search = path.slice(path.indexOf('?') + 1)

  var qs = (search.length > 0 ? search : ""),
    items = qs.length ? qs.split("&") : [],
    args = {},
    item = null,
    name = null,
    value = null;

  for (var i = 0, len = items.length; i < len; i++) {
    item = items[i].split("=")
    name = decodeURIComponent(item[0])
    value = decodeURIComponent(item[1])
    if (name.length) {
      args[name] = value
    }
  }
  return args
}

export const parseUrlSearch = (url) => {
  const startIndex = ~url.indexOf('?') ? url.indexOf('?') : 0;
  const search = url.slice(startIndex, url.length)
  return parseSearch(search)
}

/**
 * @description:  去掉对象value的首尾空格
 * @param {*} obj 原对象
 * @return {*}    去掉空格后的对象
 */
export const allTrim = (obj) => {
  return Object.entries(obj).reduce((pre, [key, val]) => {
    pre[key] = typeof val === 'string' ? val.trim() : val
    return pre
  }, {})
}

/**
 * @description:  生成uuid
 * @param {*}     temp 生成的模版
 * @return {*}    uuid
 */
export const generateUUID = (temp = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx') => {
  var d = new Date().getTime();
  var uuid = temp.replace(/[xy]/g, function(c) {
    var r = (d + Math.random()*16)%16 | 0;
    d = Math.floor(d/16);
    return (c=='x' ? r : (r&0x3|0x8)).toString(16);
  });
  return uuid;
}

/**
 * @description:  处理身份证号
 * @param {*}     
 * @return {*}   
 */
export const dealIdNum = idNum => {
  if (idNum) {
    return idNum.slice(0, 3) + '****' + idNum.slice(-4)
  } else {
    return undefined
  }
}

/**
 * @description:  处理query对象
 * @param {*}     
 * @return {*}   
 */
export const dealQuery = data => {
  let query = ''
  const queryArr = []
  const entries = Object.entries(data)

  if (entries.length > 0) {
    entries.forEach((arr) => {
      queryArr.push(`${arr[0]}=${arr[1]}`)
    })
    query = queryArr.join('&')
  }
  return query
}

/**
 * @description:
 * @param {*} urlType  类型 1-商品分组，2-商品分类，3-商品，4-店铺，5-文章合集
 * @param {*} urlId	   ID，多个Id用逗号分割
 * @param {*} callback 请求到数据后，执行回调
 */
export const getUrlData = (urlType, urlId, callback) => {
  if (!urlType || !urlId) {
    callback([], '')
    return;
  }
  return Get('public/get-url-data', {urlType, urlId}).then(res => {
    const {status, data, message} = res || {}
    if (status === 'ok') {
      callback(data || [], (data || []).map(val => val.name).toString())
      return data
    } else {
      console.error(message)
      return data
    }
  }).catch(err => {
    console.error(err)
  })
}
