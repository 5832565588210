/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2023-04-23 15:30:04
 */
import React from 'react'
import { withRouter } from 'react-router-dom'
import Routes from './routes'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'

function App () {
  return (
    <ConfigProvider locale={locale}>
      <Routes/>
    </ConfigProvider>
  )
}

export default withRouter(App);
