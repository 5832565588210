/*
 * @Description: 
 * @Author: 杨志航
 * @Date: 2022-06-13 15:18:01
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom'
import NoMatch from './pages/404'
import Main from './pages/Main'
import Login from './pages/Login'
import Organ from './pages/Organ';
import Business from './pages/Business'
import Promotion from './pages/Promotion';
import ReportForms from './pages/ReportForms';


export default () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/main" component={Main} />
      <Route path="/organ" component={Organ} />
      <Route path="/business" component={Business} />
      <Route path="/promotion" component={Promotion} />
      <Route path="/reportforms" component={ReportForms} />
      <Route component={NoMatch} />
    </Switch>
  )
}
