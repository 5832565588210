/*
 * @Description: 登录页面
 * @Author: 杨志航
 * @Date: 2023-05-24 17:55:25
 */
import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, message } from 'antd'
import { Post } from '@/libs/fetch'
import config from '@/configs/config'
import './index.scss'

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}

const loginBtn = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
}

function Login({ history }) {
  const [disabled, setDisabled] = useState(false)
  /**
   * 登录
   * @param values
   * @returns {Promise<void>}
   */
  const onFinish = (values) => {
    setDisabled(true)
    Post('system-user/login', values).then(data => {
      setDisabled(false)
      localStorage.setItem(config.storageKey.token, data.token) //设置token值
      localStorage.setItem(config.storageKey.userInfo, JSON.stringify({
        id: data.userId,    // 用户id
        name: data.nickname, //用户名
        loginDate: data.date, //登录时间
        delFlag: data.delFlag, //删除标识
        exportFlag: data.exportFlag, //导出标识
        editFlag: data.editFlag,
        dataPermission: data.dataPermission //数据权限 1：本人数据，2：本附属团队数据，3：本团队数据，4：全平台数据
      }))
      localStorage.setItem(config.storageKey.loginRemember, values.remember) //是否记住
      message.success('登录成功')
      history.push(data.redirectUrl) //跳转到对应的菜单
    }).catch(() => {
      setDisabled(false)
    })
  }

  const onFinishFailed = (errorInfo) => {
    setDisabled(false)
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    const token = localStorage.getItem(config.storageKey.token)
    const remember = localStorage.getItem(config.storageKey.loginRemember)

    if (remember == 'true' && token) {
      history.push('/main') //如果已经记录，可以直接跳转到系统内
    }
    return () => {
      setDisabled(false)
    }
  }, [])

  return (
    <div className="login-container">
      <div className="lgform">
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h3 className="lgtitle">天枢运营平台</h3>
          <Form.Item
            label="账号"
            name="username"
            rules={[
              {
                required: true,
                message: '请输入登录账号',
              },
            ]}
          >
            <Input placeholder="请输入登录账号" />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true,
                message: '请输入登录密码',
              },
              // {
              //   pattern: /^([A-Za-z0-9\.\@\!\#\$\%\^\&\*\(\)]){0,20}$/,
              //   message: '格式错误，请重新填写',
              // }
            ]}
          >
            <Input.Password placeholder="请输入登录密码" autoComplete="off" />
          </Form.Item>

          <Form.Item {...loginBtn} style={{ padding: 0 }}>
            <Button type="primary" htmlType="submit" disabled={disabled} style={{ float: 'left' }}>
              登录
            </Button>

            <Form.Item name="remember" valuePropName="checked" style={{ float: 'right' }}>
              <Checkbox>记住账号</Checkbox>
            </Form.Item>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login
