/*
 * @Description:
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-03-10 16:19:13
 */
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Menu } from 'antd'
import { Get } from '@/libs/fetch'
import Icons from '@/configs/icons'
import defaultRouter from '@/configs/router'

const SiderMenu = ({ history, ...props }) => {
  const [menudata, setMenuData] = useState([]) //菜单数据

  //异步读取菜单数据,最后一定要加一下空数组，在组件渲染时，只执行一次
  useEffect(() => {
    // 动态获取菜单
    // const siderMenu = localStorage.getItem('siderMenu')
    // if (siderMenu) {
    //   setMenuData(JSON.parse(siderMenu))
    // } else {
    //   Get('menu/get-list').then((data) => {
    //     if (data.status == 'ok') {
    //       const menu = data.data.list || []
    //       setMenuData(menu)
    //       localStorage.setItem('siderMenu', JSON.stringify(menu))
    //     }
    //   })
    // }
    // Get('menu/get-list').then((data) => {
    //   if (data.status == 'ok') {
    //     const menu = data.data.list || []
    //     setMenuData(menu)
    //     localStorage.setItem('siderMenu', JSON.stringify(menu))
    //   }
    // })
    // defaultRouter 本地配置的路由，上线后需从服务端获取
    const mainKey = props.mcurrent.split('-')[0]
    console.log(mainKey)
    const currentMenu = defaultRouter.find(item => item.key === mainKey)
    console.log(currentMenu)
    if(currentMenu && currentMenu.sub){
      console.log(currentMenu.sub)
      setMenuData(currentMenu.sub)
    }
  }, [])
  /**
   * 获取菜单
   * @returns {[]}
   */
  function getMenus(data) {
    return data.map(item => {
      const { title, key, icon, sub } = item
      let obj = {
        label: title,
        key,
        icon: Icons[icon],
      }
      if (sub) {
        obj.children = getMenus(sub)
      }
      return obj
    })
  }

  //点击菜单
  const menuClick = ({ key }) => {
    const mainKey = key.split('-')[0]
    const active = menudata.find(item => item.key === key)
    history.push(active.url)
  }


  return (
    <Menu
      theme="light"
      defaultSelectedKeys={['home']}
      selectedKeys={[props.mcurrent]}
      defaultOpenKeys={props.openkeys}
      mode="inline"
      onClick={menuClick}
      style={{height: 'calc(100vh - 64px - 48px)', overflowY: 'auto'}}
      items={getMenus(menudata)}
    />
  )
}

export default withRouter(SiderMenu)
