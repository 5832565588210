/*
 * @Description: 本地路由，上线后 从服务端获取
 * @Author: 杨志航
 * @Date: 2023-05-25 09:54:10
 */

export default {
  page:99,
  perPage:10,
  total:990,
  list:[
    {
      id: 1,
      urlOneLevel: 'https://www.baidu.com/onelevel',
      urlTwoLevel: 'https://www.baidu.com/onelevel',
      urlDirect: 'https://www.baidu.com/onelevel',
      returnRatio:99,
      userName:'开发部',
      phone:'xxx'
    }, {
      id: 2,
      urlTwoLevel: 'https://www.baidu.com/onelevel',
      urlDirect: 'https://www.baidu.com/onelevel',
      returnRatio:88,
      userName:'开发部',
      phone:'xxx'
    }
  ]
}
