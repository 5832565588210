/*
 * @Description: 主视觉
 * @Author: 杨志航
 * @Date: 2023-05-25 11:08:43
 */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'
import MainHeader from '@/components/MainHeader'
import SiderMenu from '@/components/SiderMenu'
import './index.scss'

const { Content, Sider } = Layout

const MainLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false) //左侧折叠

  return (
    <div className="main-layout-container">
      <Layout style={{ minHeight: '100vh' }}>
        <MainHeader mcurrent={props.mcurrent}/>
        <Layout >
          <Sider theme='light' width={props.mcurrent == "organ" ?'320px':'200px'} hidden={props.hideslider} collapsible={props.mcurrent == "organ" ?false:true} collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
            {
              props.mcurrent == "organ" ? (<div  style={{ padding: 0, minHeight: 360 }}>{props.sider}</div>) : (<SiderMenu mcurrent={props.mcurrent} openkeys={props.openkeys}></SiderMenu>)
            }
          </Sider>
          <Content style={{ padding: '0 16px', maxHeight: 'calc(100vh - 64px)', overflowY: 'auto' }}>
            <div  style={{ padding: 5, minHeight: 360 }}>
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}


export default MainLayout
