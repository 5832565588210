/*
 * @Description:
 * @Author: 王建军<wangjianjun@372163.com>
 * @Date: 2021-03-12 17:42:20
 */
import React from "react"
import {
    UserOutlined,HomeOutlined,SettingOutlined,LoginOutlined,LogoutOutlined,WarningOutlined,MenuFoldOutlined,BellOutlined,UsergroupAddOutlined,
    BarChartOutlined,GroupOutlined,PlayCircleOutlined,ApartmentOutlined,UploadOutlined,AppstoreAddOutlined,OrderedListOutlined,AppstoreOutlined,
    ExclamationCircleOutlined, EditOutlined, ArrowUpOutlined, ArrowDownOutlined, FileTextOutlined, FolderAddOutlined, FileUnknownOutlined,
    TrophyOutlined, ShopOutlined, CompassOutlined, TagsOutlined, ShoppingOutlined, GiftOutlined,
    NotificationOutlined, PictureOutlined, StarOutlined, MinusCircleOutlined, PlusOutlined, CloseOutlined, DeleteOutlined, ShoppingCartOutlined,
    ProfileOutlined, MoneyCollectOutlined, ToTopOutlined, DatabaseOutlined, AimOutlined, StockOutlined, EyeOutlined, FullscreenOutlined,
    CustomerServiceOutlined, ExportOutlined, UpOutlined, DownOutlined,QuestionCircleFilled,MinusSquareFilled,QuestionCircleOutlined
} from '@ant-design/icons'

function _getMapProps(prop) {
    let atr = {}
    atr = Object.assign(atr,prop)
    return {
        'user':<UserOutlined {...atr} />,
        'home':<HomeOutlined {...atr} />,
        'setting':<SettingOutlined {...atr} />,
        'login':<LoginOutlined {...atr} />,
        'logout':<LogoutOutlined {...atr} />,
        'warning':<WarningOutlined {...atr} />,
        'menu-fold':<MenuFoldOutlined {...atr} />,
        'bell':<BellOutlined {...atr} />,
        'userGroupAdd':<UsergroupAddOutlined {...atr} />,
        'barChart':<BarChartOutlined {...atr} />,
        'Group':<GroupOutlined {...atr} />,
        'PlayCircle':<PlayCircleOutlined {...atr} />,
        'Apartment':<ApartmentOutlined {...atr} />,
        'Upload':<UploadOutlined {...atr} />,
        'AppStoreAdd':<AppstoreAddOutlined {...atr} />,
        'AppStore':<AppstoreOutlined {...atr} />,
        'OrderedList':<OrderedListOutlined {...atr} />,
        'ExclamationCircle':<ExclamationCircleOutlined {...atr} />,
        'edit':<EditOutlined {...atr} />,
        'ArrowUpOutlined':<ArrowUpOutlined {...atr} />,
        'ArrowDownOutlined':<ArrowDownOutlined {...atr} />,
        'FileTextOutlined':<FileTextOutlined {...atr} />,
        'FolderAddOutlined':<FolderAddOutlined {...atr} />,
        'FileUnknownOutlined':<FileUnknownOutlined {...atr} />,
        'TrophyOutlined':<TrophyOutlined {...atr} />,
        'ShopOutlined':<ShopOutlined {...atr} />,
        'CompassOutlined':<CompassOutlined {...atr} />,
        'TagsOutlined':<TagsOutlined {...atr} />,
        'ShoppingOutlined':<ShoppingOutlined {...atr} />,
        'GiftOutlined':<GiftOutlined {...atr} />,
        'NotificationOutlined':<NotificationOutlined {...atr} />,
        'PictureOutlined':<PictureOutlined {...atr} />,
        'StarOutlined':<StarOutlined {...atr} />,
        'MinusCircleOutlined':<MinusCircleOutlined {...atr} />,
        'PlusOutlined':<PlusOutlined {...atr} />,
        'CloseOutlined':<CloseOutlined {...atr} />,
        'DeleteOutlined':<DeleteOutlined {...atr} />,
        'ShoppingCartOutlined':<ShoppingCartOutlined {...atr} />,
        'ProfileOutlined':<ProfileOutlined {...atr} />,
        'MoneyCollectOutlined':<MoneyCollectOutlined {...atr} />,
        'ToTopOutlined':<ToTopOutlined {...atr} />,
        'DatabaseOutlined':<DatabaseOutlined {...atr} />,
        'AimOutlined':<AimOutlined {...atr} />,
        'StockOutlined':<StockOutlined {...atr} />,
        'EyeOutlined':<EyeOutlined {...atr} />,
        'FullscreenOutlined':<FullscreenOutlined {...atr} />,
        'CustomerServiceOutlined':<CustomerServiceOutlined {...atr} />,
        'ExportOutlined':<ExportOutlined {...atr} />,
        'UpOutlined':<UpOutlined {...atr} />,
        'DownOutlined':<DownOutlined {...atr} />,
        'QuestionCircleFilled':<QuestionCircleFilled {...atr} />,
        'MinusSquareFilled':<MinusSquareFilled {...atr} />,
        'QuestionCircleOutlined':<QuestionCircleOutlined {...atr} />,
    }
}

/**
 * 可扩展的字体图标
 * @param props
 * @returns {*}
 * @constructor
 */
export function IconAttr(props) {
    const maptag = _getMapProps(props)
    return (
        <>{maptag[props.icon]}</>
    )
}

/**
 * 返回默认字体图标
 */
export default _getMapProps()
