/*
 * @Description: 门店管理
 * @Author: 杨志航
 * @Date: 2023-05-25 16:24:18
 */
import React, { useMemo, useEffect, useState } from 'react'
import config from '@/configs/config'
import MainLayout from '@/components/MainLayout'
import { Form,Radio,TreeSelect,Input, Tree, Popover,Button, Spin, Layout, Card, Table, Space, Popconfirm, Modal } from 'antd'
import { Get, Post } from '@/libs/fetch'
import {allTrim } from '@/libs/util'
import organJs from '@/configs/organ'
import memberJs from '@/configs/member'
import './index.scss'
import {
  PlusCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
const { Search } = Input;

const pageIndex = config.pagination.page
const perPage = config.pagination.perPage
let searchParam = {
  page: pageIndex,
  perPage: perPage,
}


const Organ = () => {

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(pageIndex)
  const [pageSize, setPageSize] = useState(perPage)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([])

  const [searchValue, setSearchValue] = useState("");
  const [treeData, setTreeData] = useState([])

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [formMember] = Form.useForm()
  const [formResetAccount] = Form.useForm()
  const [isModalOpenMember, setIsModalOpenMember] = useState(false)
  const [isModalOpenResetAccount, setIsModalOpenResetAccount] = useState(false)
	const [modalTitle, setModalTitle] = useState('新增成员')
  const [formDepartment] = Form.useForm()




  useEffect(() => {
    resetParams()
    getDepartmentList()
    
  }, [])


  /**
   * 初始化机构列表数据
   */
  const getDepartmentList = () => {
    // if (values) {
    //   const { page, perPage } = searchParam
    //   searchParam = { page, perPage, ...values }
    // }
    // setLoading(true)
    // Get('user/get-list', searchParam).then(res => {
    //   if (res.status == "ok") {
    //     setPage(searchParam.page)
    //     setPageSize(searchParam.perPage)
    //     setTotal(res.data.total)
    //     setDataSource(res.data.list)
    //   } else {
    //     message.error(res.message)
    //   }
    //   setLoading(false)
    // }).catch(err => {
    //   setLoading(false)
    // })

    const loop = (data) =>
      data.map((item) => {
        const strTitle = item.title;
        const title = (
          <>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <div>
                <span style={{fontSize: '9px'}}>{strTitle}</span>
              </div>
              <div style={{display:'flex', justifyContent:'flex-end'}} >
                  <Popconfirm
                    placement="bottomRight"
                    icon={null}
                    title={
                      <Form form={formDepartment}>
                        <Form.Item
                          label=""
                          name="subDepartment"
                          rules={[{ required: true, message: '请输入下级部门名称' }]}
                          >
                              <Input id={"add-"+item.key} style={{width:'220px', fontSize: '9px', flex:1, marginRight:'5px'}} placeholder="请输入下级部门名称" />
                          </Form.Item>
                      </Form>
                    }
                    onConfirm={()=> handleAddDepartmentAction(item)}
                    okText="确定"
                    cancelText="取消"
                    destroyTooltipOnHide={true}
                  >
                    <PlusCircleOutlined style={{marginRight: '10px'}}/>
                  </Popconfirm>


                  <Popconfirm
                    placement="bottomRight"
                    icon={null}
                    title={
                      <Form form={formDepartment}>
                        <Form.Item
                          label=""
                          name="department"
                          rules={[{ required: true, message: '请输入修改部门名称' }]}
                          >
                              <Input id={"edit-"+item.key} style={{width:'220px', fontSize: '9px', flex:1, marginRight:'5px'}} placeholder="请输入修改部门名称" defaultValue={item.title}/>
                          </Form.Item>
                      </Form>
                      
                    }
                    onConfirm={()=> handleEditDepartmentAction(item)}
                    okText="确定"
                    cancelText="取消"
                    destroyTooltipOnHide={true}
                    >
                     <EditOutlined style={{marginRight: '10px'}} />
                  </Popconfirm>
              </div>
            </div>
          </>
        );
        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
          };
        }
        return {
          title,
          key: item.key,
        };
      });

    setTreeData(loop(organJs.departmentList))
    

    setSelectedKeys([organJs.userDepartmentId])
    setExpandedKeys([organJs.userDepartmentId])
    setAutoExpandParent(true);
    //获取当前选择部门下的人员列表
    getList(organJs.userDepartmentId)

  }
  /**
   * 获取成员集合
   */
  const getList = () => {
    const params = {
      ...searchParam,
      searchKey: searchValue,
      departmentId: (selectedKeys && selectedKeys.length>0)?selectedKeys[0]:""
    }

    setLoading(true)
    Get('user/get-list', params).then(res => {
      
      // if (res.status == "ok") {
      //   setPage(searchParam.page)
      //   setPageSize(searchParam.perPage)
      //   setTotal(res.data.total)
      //   setDataSource(res.data.list)
      // } else {
      //   message.error(res.message)
      // }


      setPage(searchParam.page)
      setPageSize(searchParam.perPage)
      setTotal(memberJs.total)
      setDataSource(memberJs.memberList)

      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  /**
   * Tree树形 点击展开收起操作
   * @param {展开的key} newExpandedKeys 
   */
  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  /**
   * 搜索
   * @param {搜索内容} value 
   */
  const onSearch = (value) => {
    setSearchValue(value);
    console.log('onSearch', value);
    resetParams();
    getList();
  };
  
  /**
   * tree点击操作
   * @param {选中的key} selectedKeysValue 
   * @param {*} info 
   */
  const onSelect = (selectedKeysValue, info) => {
    if(selectedKeysValue && selectedKeysValue.length >0){
      setSelectedKeys(selectedKeysValue);
      resetParams();
      getList(organJs.userDepartmentId);
    }
  };


  /**
   * 禁用操作
   * @param {*} id 
   * @param {*} action 
   */
  const handleForbiddenAction = (id, action) => {
    setLoading(true)
    Post(`user/${action}`, { id }).then(res => {
      if (res.status == "ok") {
        getList()
      } else {
        message.error(res.message)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  /**
   * 新增部门
   * @param {父部门信息} item 
   */
  const handleAddDepartmentAction = (item) =>{
    return new Promise((resolve, reject) => {
      formDepartment.setFieldsValue(allTrim(formDepartment.getFieldsValue()))
      formDepartment.validateFields().then((values) => {
        resolve(null)
        console.log(111,values.department)
        console.log(111,values.subDepartment)

        //新增后刷新部门
        getDepartmentList();
      }).catch((errorInfo) => {
        reject(null)
      })
    });
  }

  /**
   * 修改部门
   * @param {修改部门信息} item 
   */
  const handleEditDepartmentAction = (item) =>{
    return new Promise((resolve, reject) => {
      formDepartment.setFieldsValue(allTrim(formDepartment.getFieldsValue()))
      formDepartment.validateFields().then((values) => {
        resolve(null)

        console.log(222,values.department)
        console.log(222,values.subDepartment)

        //新增后刷新部门
        getDepartmentList();
      }).catch((errorInfo) => {
        reject(null)
      })
    });
  }

  /**
   * 新增成员操作
   */
  const handleAddMemberAction = () => {
    setIsModalOpenMember(true)
    setTitle('新增成员')
    formMember.setFieldsValue({
			name: undefined
    })
  }

  /**
   * 编辑操作
   * @param {*} item 
   */
  const handleEditMemberAction = (item) => {
		setIsModalOpenMember(true)
		setTitle('成员信息')
		setLoading(true)
		// Get('app-user-mobile-group/detail', { id }).then(res => {
			// if (res.status == "ok") {
				formMember.setFieldsValue({
					name: res.data.name
				})
				if (res.data.file && res.data.file.length > 0) {
					formMember.setFieldsValue({
						file: res.data.file
					})
					let arr = res.data.file.split('/public/')
					setFileList([{url: res.data.file, status: "done", name: arr[1]}])
				}
			// } else {
				// message.error(res.message)
			// }
			setLoading(false)
		// }).catch(err => setLoading(false))
  }

  /**
   * 重置
   * @param {*} item 
   */
  const handleResetAction = (item) => {
    // setLoading(true)
    // Post(`user/${action}`, { id }).then(res => {
    //   if (res.status == "ok") {
    //     getList()
    //   } else {
    //     message.error(res.message)
    //   }
    //   setLoading(false)
    // }).catch(err => {
    //   setLoading(false)
    // })
    console.log(item)
    setIsModalOpenResetAccount(true)
    formResetAccount.setFieldsValue({
      account: item.account
    })
  }


  /**
   * table 翻页
   * @param {*} pagination 
   * @param {*} filters 
   * @param {*} sorter 
   */
  const onChangeTable = (pagination, filters, sorter) => {
    searchParam.page = pagination.current
    searchParam.perPage = pagination.pageSize
    getList()
  }

  const onFinishMember = () => {
		formMember.validateFields().then((values) => {
			console.log('values=>', values)
			if (values.file && values.file.length > 0) {
				values.file = values.file[0].url
			}
			if (id) {
				values.id = id
			}
			// 去掉参数中的无效值
      Object.keys(values).forEach(key => {
        if ([undefined, null, ''].includes(values[key])) {
          delete values[key]
        }
      })
			setLoading(true)
			Post(`app-user-mobile-group/${values.id ? 'edit' : 'add'}`, values).then((res) => {
				setLoading(false)
				if (res.status == 'ok') {
					message.success("保存成功")
					formMember.setFieldsValue({
						name: undefined,
            account: undefined,
            password: undefined,
            department: undefined,
            role: undefined
					})
					setIsModalOpenMember(false)
					getList()
				} else {
					message.error(res.message)
				}
			})
		})
	}

  //关闭新增/编辑弹窗
	const onCancelMember = () => {
		formMember.setFieldsValue({
			name: undefined,
      account: undefined,
      password: undefined,
      department: undefined,
      role: undefined
    
    })
		setIsModalOpenMember(false)
	}

  const onFinishResetAccount = () => {
		formResetAccount.validateFields().then((values) => {
			console.log('values=>', values)
			if (values.file && values.file.length > 0) {
				values.file = values.file[0].url
			}
			if (id) {
				values.id = id
			}
			// 去掉参数中的无效值
      Object.keys(values).forEach(key => {
        if ([undefined, null, ''].includes(values[key])) {
          delete values[key]
        }
      })
			setLoading(true)
			Post(`app-user-mobile-group/${values.id ? 'edit' : 'add'}`, values).then((res) => {
				setLoading(false)
				if (res.status == 'ok') {
					message.success("保存成功")
					formResetAccount.setFieldsValue({
            account: undefined,
            password: undefined
					})
					setIsModalOpenResetAccount(false)
				} else {
					message.error(res.message)
				}
			})
		})
	}

  //关闭新增/编辑弹窗
	const onCancelResetAccount = () => {
		formResetAccount.setFieldsValue({
      account: undefined,
      password: undefined
    })
		setIsModalOpenResetAccount(false)
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 12 },
		}
	}

  /**
   * 初始化参数
   */
  const resetParams = () => {
    searchParam = {
      page: pageIndex,
      perPage: perPage,
    }
    setPageSize(perPage)
  }

  const SiderView = (<div>
    <Search
      style={{
        marginTop: 5,
        marginBottom: 8,
      }}
      placeholder="请输入员工姓名"
      onSearch={onSearch}
      enterButton="搜索"
    />
    <Tree
      showLine={true}
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      treeData={treeData}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      blockNode={true}
    />
  </div>)


  const cols = [{
    title: '员工ID',
    dataIndex: 'memberId'
  }, {
    title: '员工姓名',
    dataIndex: 'name'
  }, {
    title: '登录账户',
    dataIndex: 'account',
  }, {
    title: '角色',
    dataIndex: 'role',
  }, {
    title: '部门',
    dataIndex: 'department',
  }, {
    title: '状态',
    dataIndex: 'isForbidden',
    render: (text, record) => (text===1) ? '禁用' : '启用'
  }, {
    title: '操作',
    key: 'action',
    width: 160,
    align: 'center',
    fixed: 'right',
    render: (text, record) => (
      <Space align="start">
        <a onClick={() => handleEditMemberAction(record.id)}>编辑</a>
        {
          record.isForbidden === 1 ?
            <Popconfirm
              title={"请确认是否启用账户 "+record.name}
              onConfirm={() => handleForbiddenAction(record.id, 'unfreeze')}
              okText="确定"
              cancelText="取消"
            >
              <a>启用</a>
            </Popconfirm>
            :
            <Popconfirm
              title={"请确认是否禁用账户 " + record.name}
              onConfirm={() => handleForbiddenAction(record.id, 'freeze')}
              okText="确定"
              cancelText="取消"
            >
              <a>禁用</a>
            </Popconfirm>
            
        }
        <a onClick={() => handleResetAction(record)}>重置</a>
      </Space>
    )
  }]


  return (
    <div className="organ-container">
      <MainLayout mcurrent='organ' openkeys={['organ']} sider={SiderView}>
        <Spin spinning={loading} tip="Loading...">
          <Card>
            <Layout.Content>
            <div className="table-btn-super" style={{ justifyContent: 'flex-end' }}>
                <Button type='primary' onClick={()=> handleAddMemberAction()}>新增成员</Button>
              </div>

              <Table
                columns={cols}
                rowKey="id"
                dataSource={dataSource}
                scroll={{ x: 'max-content' }}
                bordered
                pagination={{
                  defaultCurrent: pageIndex,
                  pageSize: pageSize,
                  total: total,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  current: page,
                }}
                onChange={onChangeTable}
                footer={() => (<>
                  第{page}页，共{Math.ceil(total / searchParam.perPage)}页，共{total}条
                </>)}
              ></Table>
            </Layout.Content>
          </Card>
        </Spin>
      </MainLayout>
      <Modal
        title={modalTitle}
        open={isModalOpenMember}
        onOk={() => onFinishMember()}
        onCancel={onCancelMember}
        destroyOnClose
        cancelText="取消"
        okText="确认">
        <Form form={formMember} onFinish={onFinishMember} {...formItemLayout} >
            <Form.Item
                label="员工姓名"
                name="name"
                rules={[{ required: true, message: '请输入员工姓名' }]}
            >
                <Input placeholder="请输入员工姓名" />
            </Form.Item>
            <Form.Item
                label="登录账户"
                name="account"
                rules={[{ required: true, message: '请输入登录账户' }]}
            >
                <Input placeholder="请输入登录账户" />
            </Form.Item>
            <Form.Item
                label="登录密码"
                name="password"
                rules={[{ required: true, message: '请输入登录密码' }]}
            >
                <Input.Password placeholder="请输入登录密码" />
            </Form.Item>
            <Form.Item
                label="部门"
                name="department"
                rules={[{ required: true, message: '请选择部门' }]}
            >
                <TreeSelect
                  showSearch
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="请选择部门"
                  allowClear
                  treeDefaultExpandAll
                  // onChange={onChange}
                  treeData={treeData}
                />
            </Form.Item>
            <Form.Item
                label="角色"
                name="role"
                rules={[{ required: true, message: '请选择角色' }]}
            >
                <Radio.Group>
                    <Radio value={1}>超级管理员</Radio>
                    <Radio value={2}>管理员</Radio>
                    <Radio value={3}>运营</Radio>
                    <Radio value={4}>销售</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    </Modal>

    <Modal
        title={"重置账户"}
        open={isModalOpenResetAccount}
        onOk={() => onFinishResetAccount()}
        onCancel={onCancelResetAccount}
        destroyOnClose
        cancelText="取消"
        okText="确认">
        <Form form={formResetAccount} onFinish={onFinishResetAccount} {...formItemLayout} >
            <Form.Item
                label="登录账户"
                name="account"
                rules={[{ required: true, message: '请输入登录账户' }]}
            >
                <Input placeholder="请输入登录账户" disabled/>
            </Form.Item>
            <Form.Item
                label="登录密码"
                name="password"
                rules={[{ required: true, message: '请输入登录密码' }]}
            >
                <Input.Password placeholder="请输入登录密码" />
            </Form.Item>
        </Form>
    </Modal>

    </div>
  )
}

export default Organ
